<template>
  <Teleport to="body">
    <organism-static-modal modalSize="modal-xl" ref="organism-static-modal">
      <template #header-right>
        <dot-button type="button" class="bg-dot-orange rounded-5" style="padding:0 32px; height:48px; font-size: 20px;"
                    @click="onConfirmButton">{{ $t('확인') }}
        </dot-button>
      </template>
      <template #body>
        <div class="d-flex align-items-center" style="height: 40rem; justify-content: center;">
          <div class="h-100" style="flex-basis: 45%">
            <textarea class="w-100 h-100" v-model="inkWords"></textarea>
            <div style="flex-basis: 10%">
              <dot-button type="button" class="bg-dot-orange w-100 h-100" @click="onClickTranslateToBraille">{{ $t('번역') }}</dot-button>
            </div>
          </div>

          <div class="icon">
            <svg width="28" height="26" viewBox="0 0 28 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.0649 2.6875L26.375 13L16.0649 23.3125M1.625 13L25.6016 13" stroke="#464646" stroke-width="3" stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round"/>
            </svg>
          </div>

          <div class="h-100" style="flex-basis: 45%; overflow-y: scroll">
            <!-- pages -->
            <template v-if="pages.length > 0">
              <template v-for="(page, index) in pages" v-bind:key="index">
                <textarea class="w-100 three-hundred-cells-braille" v-model="pages[index]" readonly :disabled="index >= maxPage"></textarea>
                <div class="number">{{ index + 1 }}/{{ pages.length > 100 ? maxPage : pages.length }}</div>
              </template>
            </template>
            <template v-else>
              <textarea class="w-100 three-hundred-cells-braille" readonly/>
              <div class="number">1/1</div>
            </template>
          </div>
        </div>
      </template>
    </organism-static-modal>
  </Teleport>
</template>

<script>
import { mapGetters } from "vuex";
import { Braille } from "../js/Braille";
import { DotpadSDK } from "../js/DotpadSDK";
import { DTMS, Page } from "../js/common";
import OrganismStaticModal from "@/components/Organism/OrganismStaticModal.vue";
import DotButton from "@/components/atoms/DotButton.vue";

export default {
  name: "PdfUploadModal",
  components: {
    OrganismStaticModal,
    DotButton,
  },
  data() {
    return {
      NUMBER_OF_HORIZONTAL_CELLS: 20,
      NUMBER_OF_VERTICAL_CELLS: 10,
      NUMBER_OF_DOT_CELL_HEIGHT: 4,
      NUMBER_OF_TOTAL_CELL_HEIGHT: 40,
      drawDotCellLine: 200,
      dotpadsdk: new DotpadSDK(),
      title: "",
      inkWords: "",
      pages: [],
      drawPoints: [],
    }
  },
  mounted() {
    this.$watch(
      "$refs.organism-static-modal.isShown",
      () => {
        const isShown = this.$refs["organism-static-modal"].isShown;

        if (isShown) {
          this.$parent.$parent.$parent.removeAllEventListener();
        } else {
          this.$parent.$parent.$parent.addHotkeyEventListeners();
        }
      }
    );
  },
  computed: {
    ...mapGetters("canvasPage", ["maxPage"]),
    ...mapGetters("braille", ["kind", "language", "grade", "rule", "pin"]),
    TWO_HUNDRED_CELLS() {
      return this.NUMBER_OF_HORIZONTAL_CELLS * this.NUMBER_OF_VERTICAL_CELLS;
    },
    greatGrandParent() {
      return this.$parent.$parent.$parent;
    },
    drawCellHeight() {
      return this.drawDotCellLine;
    }
  },
  methods: {
    open() {
      this.$refs["organism-static-modal"].open();
      this.initDataBlankPoint(this.pin);
    },
    async onClickTranslateToBraille() {
      const response = await this.fetchTextToBraille();
      let brailleUnicode;
      this.pages = []

      if (response.length === 0) {
        return;
      }

      brailleUnicode = this.brailleUnicode(response);

      for (let i = 0; i <= brailleUnicode.length / this.drawCellHeight; i++) {
        const newStr = brailleUnicode.substring(i * this.drawCellHeight, (i + 1) * this.drawCellHeight);

        if (newStr !== "") {
          this.pages.push(newStr);
        }
      }
    },
    async fetchTextToBraille() {
      this.showSpinner();
      return await this.greatGrandParent.fetchTextToBraille("content", this.inkWords)
        .finally(() => {
          this.hideSpinner();
        });
    },
    brailleUnicode(hex) {
      const VOXEL_ROW_NUM = this.greatGrandParent.VOXEL_ROW_NUM;

      hex = hex.map(hexString => {
        // Dot 일본어일 경우
        if (hexString.includes("brailleCode:")) {
          hexString = hexString.split("brailleCode:")[1].substr(0, hexString.length / 2);
        }

        const strippedHex = hexString.replace(/\s/g, "");

        if (strippedHex.length === 0 || strippedHex.length % VOXEL_ROW_NUM !== 0) {
          const paddingLength = (VOXEL_ROW_NUM - strippedHex.length % VOXEL_ROW_NUM) / 2;
          return hexString + (" 00".repeat(paddingLength));
        } else {
          return hexString;
        }
      });

      const hexData = hex.join(' ');

      return Braille.toChar(hexData);
    },
    async onConfirmButton() {
      const pages = [];
      let dtms;

      await this.onClickTranslateToBraille();
      document.getElementById("spinner").style.zIndex = "1000";
      this.showSpinner();

      for (let i = 0; i < this.pages.length; i++) {
        if (i >= this.maxPage) {
          break;
        }
        const newPage = this.createNewPage(i);
        pages.push(newPage);
      }

      dtms = this.createNewDTMS(pages);

      this.$refs["organism-static-modal"].modal._element.style.display = "none";
      await this.greatGrandParent.newPage();
      this.removeLastPageIfBlank();
      this.bindDTMSData(dtms);
      this.greatGrandParent.isCanvasDirty = true;

      this.hideSpinner();
      document.getElementById("spinner").removeAttribute('style');

      this.$refs["organism-static-modal"].hide();
    },
    /**
     * newPage 호출하면서 생긴 빈 페이지 삭제
     */
    removeLastPageIfBlank() {
      const lastIndex = this.greatGrandParent.pages.length - 1; // 마지막 인덱스 계산
      const lastPage = this.greatGrandParent.pages[lastIndex]; // 마지막 아이템 가져오기
      const isLastPageBlank = lastPage.graphic.data.every(data => data === false);

      if (isLastPageBlank) {
        this.greatGrandParent.pages.pop();
      }
    },
    createNewPage(index) {
      const hex = this.convertToBrailleHex(this.pages[index]);
      const paddedHex = hex.padEnd(600, "0");
      return new Page("", index + 1, {name: "", data: paddedHex}, {name: "", data: "", plain: ""}, [], []);
    },
    convertToBrailleHex(unicode) {
      const hex = Braille.toHex(unicode)?.replace(/\s/g, '');
      return this.dotpadsdk.changeGraphicDisplayMultiline(hex, this.pin, this.drawPoints);
    },
    createNewDTMS(pages) {
      return new DTMS({
        title: this.title,
        text: this.inkWords,
        lang: this.language,
        lang_option: this.grade,
        items: pages
      });
    },
    bindDTMSData(dtms) {
      this.greatGrandParent.$refs.navigation.bindDtmsData(dtms);
      this.greatGrandParent.dtms = dtms;
      this.greatGrandParent.loadPage(0);
    },
    showSpinner() {
      document.getElementById("spinner").classList.remove('d-none');
    },
    hideSpinner() {
      document.getElementById("spinner").classList.add('d-none');
    },
    drawBrailleCellPoint(cellHeight){
      let brailleCellPoint = [];
      for(let i = 1; i <= this.NUMBER_OF_TOTAL_CELL_HEIGHT; i += cellHeight) {
        if((this.pin / 2) === 3) {
          brailleCellPoint.push([i, i+1, i+2]);
        } else {
          brailleCellPoint.push([i, i+1, i+2, i+3]);
        }
      }

      let drawCellPoints = [];
      for (let i = 1; i <= this.NUMBER_OF_TOTAL_CELL_HEIGHT; i += 4) {
        let range = [i, i+1, i+2, i+3];

        let drawCellPoint = range.map(num =>
            brailleCellPoint.some(cellPoint => cellPoint.includes(num)) ? 1 : 0
        );

        drawCellPoints.push(drawCellPoint);
      }
      return drawCellPoints;
    },
    initDataBlankPoint(pinPoint) {
      let blankPoint = pinPoint !== 8 ? 3 : 2;
      let cellHeight = pinPoint / 2 + Number(blankPoint);
      this.drawPoints = this.drawBrailleCellPoint(cellHeight);
      let maxLines = Math.floor(this.NUMBER_OF_TOTAL_CELL_HEIGHT / (cellHeight));
      const remainingCells = this.NUMBER_OF_TOTAL_CELL_HEIGHT % (cellHeight);
      if (remainingCells >= (pinPoint /2) ){
          maxLines++;
      }
      this.drawDotCellLine = this.NUMBER_OF_HORIZONTAL_CELLS * this.NUMBER_OF_VERTICAL_CELLS * (maxLines / 10);
    }
  }
}
</script>

<style scoped>
textarea {
  resize: none;
}

.three-hundred-cells-braille {
  height: 20.6rem;
  font-family: 'Noto Sans Symbols 2',sans-serif;
  font-size: 1.6rem;
  letter-spacing: 0.31rem;
}

.number {
  margin-bottom: 20px;
  text-align: center;
  font-size: 1rem;
}
.icon {
  margin: 0 20px;
}

</style>
