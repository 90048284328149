 <template>
  <div v-if="isShown" class="modal fade" ref="static-backdrop" data-bs-backdrop="static" tabindex="-1" aria-hidden="true">
    <div :class="modalSize"
         class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content rounded-5">
        <div class="modal-header">
        </div>
        <div class="modal-body">
          <slot name="body"></slot>
        </div>
        <div class="modal-footer">
          <div class="d-flex flex-wrap align-items-center gap-3 ms-auto">
            <slot name="footer"></slot>
            <slot name="header-left"></slot>
            <dot-button type="button" class="bg-dot-gray rounded-5" style="height:48px; font-size: 20px;" data-bs-dismiss="modal">{{ $t('취소') }}</dot-button>
            <slot name="header-right"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DotButton from "@/components/atoms/DotButton.vue";
import {Modal} from "bootstrap";

export default {
  name: "OrganismStaticModal",
  components: {
    DotButton,
  },
  props: {
    modalSize: String, // 'modal-sm', 'modal-lg', 'modal-xl'
  },
  data() {
    return {
      isShown: false
    }
  },
  methods: {
    open() {
      this.isShown = true;
      this.$nextTick(() => {
        const modalEl = this.$refs['static-backdrop'];
        modalEl.addEventListener('hide.bs.modal', this.close);

        this.modal = Modal.getOrCreateInstance(modalEl);
        this.modal.show();
      });
    },
    close() {
      this.isShown = false;
    },
    hide() {
      this.modal.hide();
      this.isShown = false;
    }
  }
}
</script>

<style scoped>
.modal-dialog-scrollable .modal-content {
  height: 830px;
  max-height: none;
}
.modal-header {
  border-bottom: 0;
}

.modal-footer {
  border-top: 0;
}
</style>
